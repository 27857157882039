import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'h/inicio',
    pathMatch: 'full',
  }, {
    path: 'h',
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/page-layout/page-layout.module#PageLayoutModule',
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'h/inicio',
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,
      {
    useHash: false,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
