import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeolocalizationIPService {
  IP_DATA_URL: string;
  IP_DATA_KEY: string;

  constructor( private http: HttpClient ) {
    this.IP_DATA_URL = environment.IP_DATA_URL;
    this.IP_DATA_KEY = environment.IP_DATA_KEY;
  }

  getData() {
    return this.http.get(`${this.IP_DATA_URL}?api-key=${this.IP_DATA_KEY}`, {
      observe: 'response'
    });
  }
}
