<!--============= Footer Section Starts Here =============-->
<footer class="footer-section bg_img" data-background="./assets/images/footer/footer-bg.jpg">
  <div class="container">
      <div class="footer-top padding-top padding-bottom">
          <div class="logo">
              <a href="#0">
                  <img src="./assets/images/logo/footer-logo.png" alt="logo">
              </a>
          </div>
          <!--<ul class="social-icons">
              <li>
                  <a href="#0"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                  <a href="#0" class="active"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                  <a href="#0"><i class="fab fa-pinterest-p"></i></a>
              </li>
              <li>
                  <a href="#0"><i class="fab fa-google-plus-g"></i></a>
              </li>
              <li>
                  <a href="#0"><i class="fab fa-instagram"></i></a>
              </li>
          </ul>-->
      </div>
      <div class="footer-bottom">
          <ul class="footer-link">
              <li>
                <a href="/h/inicio">{{ 'HEADER.MENU.HOME' | translate }}</a>
            </li>
              <li>
                <a href="/h/about-us">{{ 'HEADER.MENU.ABOUTUS' | translate }}</a>
              </li>
              <li>
                <a href="/h/contact">{{ 'HEADER.MENU.CONTACT' | translate }}</a>
              </li>
          </ul>
      </div>
      <div class="copyright">
          <p>
            &copy; {{ test | date: "yyyy" }} <a href="https://millionsolutions.dev" class="font-weight-bold ml-1" target="_blank">Million Solutions</a>
          </p>
      </div>
  </div>
</footer>
<!--============= Footer Section Ends Here =============-->