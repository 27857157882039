 <!--============= ScrollToTop Section Starts Here =============-->
 <div class="preloader">
  <div class="preloader-inner">
      <div class="preloader-icon">
          <span></span>
          <span></span>
      </div>
  </div>
</div>
<a href="#0" class="scrollToTop"><i class="fas fa-angle-up"></i></a>
<div class="overlay"></div>
<!--============= ScrollToTop Section Ends Here =============-->


<!--============= Header Section Starts Here =============-->
<header class="header-section header-white-dark">
  <div class="container">
      <div class="header-wrapper">
          <div class="logo">
              <a href="index-3.html">
                  <img src="./assets/images/logo/logo2.png" alt="logo">
              </a>
          </div>
          <ul class="menu">
              <li>
                  <a href="/h/inicio">{{ 'HEADER.MENU.HOME' | translate }}</a>
              </li>
              <li>
                <a href="/h/about-us">{{ 'HEADER.MENU.ABOUTUS' | translate }}</a>
            </li>
              <li>
                <a href="/h/contact">{{ 'HEADER.MENU.CONTACT' | translate }}</a>
            </li>
          </ul>
          <div class="header-bar d-lg-none">
              <span></span>
              <span></span>
              <span></span>
          </div>
          <div class="header-right" id="selectorWrapper">
            <ul class="menu">
                <li>
                    <a href="javascript:void(0)">{{ langSelect | uppercase }}</a>
                    <ul class="submenu">
                        <li>
                            <a (click)="changeLang('en')" href="javascript:void(0)">EN</a>
                            <a (click)="changeLang('es')" href="javascript:void(0)">ES</a>
                        </li>
                    </ul>
                </li>
            </ul>
          </div>
      </div>
  </div>
</header>
<!--============= Header Section Ends Here =============-->