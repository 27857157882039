import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DynamicScriptLoaderService } from '../core/services/dynamic-script-loader-service.service';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { NiceSelectModule } from 'ng-nice-select';

@NgModule({
  imports:      [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    NiceSelectModule
  ],
  declarations: [
    SpinnerComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerComponent,
    TranslateModule,
    NiceSelectModule
  ],
  providers: [
    DynamicScriptLoaderService,
    TranslatePipe

  ]
})
export class SharedModule { }
