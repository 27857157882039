import { Component, OnInit } from '@angular/core';
import { DynamicScriptLoaderService } from 'src/app/core/services/dynamic-script-loader-service.service';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit {

  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService
  ) { }

  ngOnInit() {
    this.loadScripts();

  }
  private loadScripts() {
    this.dynamicScriptLoader.load('main').then(data => {
    }).catch(error => console.log(error));
  }
}
