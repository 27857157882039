import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'million-solutions';
  constructor(private spinner: NgxSpinnerService) {}
  ngOnInit() {
    // this.spinner.show();
  }
}
